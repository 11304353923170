import { zodResolver } from "@hookform/resolvers/zod";
import { t, Trans } from "@lingui/macro";
import { ArrowRight } from "@phosphor-icons/react";
import { loginSchema } from "@rocket-resume/dto";
import { usePasswordToggle } from "@rocket-resume/hooks";
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@rocket-resume/ui";
import { cn } from "@rocket-resume/utils";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

import { useLogin } from "@/client/services/auth";
import { useAuthProviders } from "@/client/services/auth/providers";

type FormValues = z.infer<typeof loginSchema>;

export const LoginPage = () => {
  const { login, loading } = useLogin();

  const { providers } = useAuthProviders();
  const emailAuthDisabled = !providers?.includes("email");

  const formRef = useRef<HTMLFormElement>(null);
  usePasswordToggle(formRef);

  const form = useForm<FormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: { identifier: "", password: "" },
  });

  const onSubmit = async (data: FormValues) => {
    try {
      await login(data);
    } catch {
      form.reset();
    }
  };

  return (
    <div className="space-y-8">
      <Helmet>
        <title>
          {t`Sign in to your account`} - {t`Rocket Resume`}
        </title>
      </Helmet>

      <div className="space-y-1.5">
        <h2 className="text-[1.2rem] xs:text-3xl font-semibold tracking-tight text-[#FFFFFF]">{t`Sign in to your account`}</h2>
        <h6 className="font-inter text-[0.8rem] xs:text-sm font-bold leading-[14px] xs:leading-[18px] text-left text-[#9D9D9D]">{t`Get inspired by 200+ Free Resume Examples and Templates`}</h6>
      </div>
      <div className={cn(emailAuthDisabled && "hidden")}>
        <Form {...form}>
          <form
            ref={formRef}
            className="flex flex-col gap-y-4 text-[#FFFFFF]"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              name="identifier"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email/Username</FormLabel>
                  <FormControl>
                    <Input placeholder="john.doe@example.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="password"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t`Password`}</FormLabel>
                  <FormControl>
                    <Input placeholder="password" type="password" {...field} />
                  </FormControl>
                  <FormDescription>
                    <Button asChild variant="link" className="w-[100%] text-[#f6f6f6] px-4 flex justify-end">
                      <Link to="/auth/forgot-password">{t`Forgot Password?`}</Link>
                    </Button>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="mt-4 flex items-center gap-x-4">
              <Button type="submit" disabled={loading} className="text-[#000] flex-1 bg-[#FFEB00] hover:bg-[#e1e0e0]">
                {t`Sign in`}
              </Button>
            </div>
          </form>
        </Form>
      </div>
      <div className={cn(emailAuthDisabled && "hidden mt-[10px]")}>
          <span className="opacity-75 text-[#fff]">{t`Don't have an account?`}</span>
          <Button asChild variant="link" className="px-1.5 text-[#9D9D9D]">
            <Link to="/auth/register">
              {t({ message: "Create one now", context: "This is a link to create a new account" })}{" "}
              <ArrowRight className="ml-1" />
            </Link>
          </Button>
        </div>
        <div className="text-[#666666] text-sm font-bold mt-20">© Copyright 2024, All Rights Reserved</div>
    </div>
  );
};
